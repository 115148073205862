<div class="full-screen-container">
    <div class="row gantt-toolbox p-2 shadow-sm mt-2 mb-3" style="background-color: white; margin-right: 6px">
        <div class="col-md-8 d-flex flex-row justify-content-start">
            <div>
                <span style="font-size: 12px; margin-right: 8px">W.B.S</span>
                <nz-switch nzSize="small" [(ngModel)]="showWBS" (ngModelChange)="toggleWbsDisplay($event)"></nz-switch>
            </div>
            <!-- <div style="margin-left: 8px;">
                    <nz-radio-group [(ngModel)]="expandLevel" (ngModelChange)="expandCollapse($event)" nzButtonStyle="solid"
                        nzSize="small">
                        <label nz-radio-button nzValue="collapseAll">Collapse</label>
                        <label nz-radio-button nzValue="expandAll">Expand</label>
                    </nz-radio-group>
                </div> -->
            <div style="margin-left: 8px">
                <!-- <button nzSize="small" nz-button nz-dropdown [nzDropdownMenu]="menuCollapse">
                        Collapse
                        <span nz-icon nzType="down"></span>
                    </button>
        
                    <nz-dropdown-menu #menuCollapse="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item *ngFor="let level of getLevels()" (click)="onLevelSelect(level, 'collapse')">
                                <a>Level {{ level }}</a>
                            </li>
                        </ul>
                    </nz-dropdown-menu> -->

                <!-- <button nzSize="small" nz-button nz-dropdown [nzDropdownMenu]="menuExpand">
                        View level
                        <span nz-icon nzType="down"></span>
                    </button>
    
                    <nz-dropdown-menu #menuExpand="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item *ngFor="let level of getLevels()" (click)="onLevelSelect(level, 'expand')">
                                <a>Level {{ level }}</a>
                            </li>
                        </ul>
                    </nz-dropdown-menu> -->
                <p-dropdown class="level-dropdown" [options]="getLevels()" [(ngModel)]="selectedLevel"
                    (ngModelChange)="onLevelSelect($event, 'expand')" optionValue="value" optionLabel="label"
                    placeholder="View level" />
            </div>
            <div style="margin-left: 8px">
                <button nzTooltipTitle="Apply filters" nzTooltipPlacement="top" nz-button nz-tooltip nzType="default"
                    nzSize="small" (click)="openFilterDrawer()" [disabled]="!displayGantt">
                    <i class="fas fa-filter" style="margin-right: 4px"></i>Filters <span *ngIf="appliedFiltersCount > 0"
                        style="margin-left: 4px;color: #FF4D4F; font-weight: 650;">({{appliedFiltersCount}})</span>
                </button>
            </div>
            <div style="margin-left: 8px">
                <div>
                    <button [nzLoading]="dataSaveLoader" nzTooltipTitle="Save plan" nzTooltipPlacement="top" nz-button
                        nz-tooltip nzType="primary" nzSize="small"
                        [disabled]="!(displayGantt && canSave && canWaveSave && isAnyUserEditing)"
                        (click)="saveGanttData()">
                        <i class="fas fa-save" style="margin-right: 4px"></i>Save
                    </button>
                    <button style="margin-left: 8px ;border-radius: 2px " nzSize="small" nz-button
                        (click)="editplanner()" nz-tooltip nzTooltipTitle="Edit the project planner"
                        [disabled]="!(displayGantt && canSave && canWaveSave && isEditButtonDisable)">
                        <i class="fa-solid fa-pen-to-square"></i>
                    </button>
                    <!-- <button type="button" (click)="confirm($event)" pButton label="Delete"></button> -->
                    <!-- <p-confirmPopup #confirmPopupRef>
                        <ng-template pTemplate="headless" let-message let-test>
                            <div class="border-round p-3">
                                <span>{{ message.message }}</span>
                                <div class="flex align-items-center gap-2 mt-3">
                                    <p-button (onClick)="accept()" label="Save" size="small" />
                                    <p-button (onClick)="reject()" label="Cancel" [outlined]="true" size="small" />
                                </div>
                            </div>
                        </ng-template>
                    </p-confirmPopup> -->
                </div>
            </div>
            <div style="margin-left: 8px">
                <nz-button-group>
                    <button nzTooltipTitle="Zoom In" nzTooltipPlacement="top" nz-button nz-tooltip nzType="default"
                        nzSize="small" (click)="zoomInView()">
                        <i class="fas fa-search-plus"></i>
                    </button>
                    <button nzTooltipTitle="Zoom Out" nzTooltipPlacement="top" nz-button nz-tooltip nzType="default"
                        nzSize="small" (click)="zoomOutView()">
                        <i class="fas fa-search-minus"></i>
                    </button>
                    <button nzTooltipTitle="Zoom to fit" nzTooltipPlacement="top" nz-button nz-tooltip nzType="default"
                        nzSize="small" (click)="zoomToFit()">
                        <i class="fas fa-compress-arrows-alt"></i>
                    </button>
                    <button nzTooltipTitle="Previous time span" nzTooltipPlacement="top" nz-button nz-tooltip
                        nzType="default" nzSize="small" (click)="shiftLeft()">
                        <i class="fas fa-angle-left"></i>
                    </button>
                    <button nzTooltipTitle="Next time span" nzTooltipPlacement="top" nz-button nz-tooltip
                        nzType="default" nzSize="small" (click)="shiftRight()">
                        <i class="fas fa-angle-right"></i>
                    </button>
                </nz-button-group>
            </div>
            <div style="margin-left: 8px">
                <!-- <button nz-button nzType="primary" nzSize="small" (click)="showCriticalPath()"><i class="fas fa-level-down"
                            style="margin-right: 2px;"></i> Critical Path</button> -->
                <!-- <label nz-checkbox [(ngModel)]="criticalPath" (ngModelChange)="showCriticalPath($event)">Critical
                        Path</label> -->
                <!-- <p-checkbox [(ngModel)]="checked" (ngModelChange)="showCriticalPath($event)" [binary]="true"
                        inputId="binary" /> -->
                <!-- <label style="font-size: 12px; margin-right: 4px;" for="binary">Highlight Critical Path</label>
                    <nz-switch nzSize="small" [(ngModel)]="checked" (ngModelChange)="showCriticalPath($event)"></nz-switch> -->
                <button nzSize="small" nzType="default" nzTooltipTitle="Highlight Critical Path"
                    nzTooltipPlacement="top" nz-button nz-tooltip [ngClass]="{
                        'kt-critical-path-btn-default': checked === false,
                        'kt-critical-path-btn-active': checked === true
                      }" (click)="showCriticalPath()" [disabled]="!displayGantt">
                    <i class="fas fa-level-down" style="margin-right: 2px"></i>Critical
                    Path
                </button>
            </div>
            <div style="margin-left: 8px" *ngIf="readOnlyTagVisible">
                <p-tag [style]="{ background: '#007ad9'}">
                    Read Only
                </p-tag>
            </div>

            <!-- <button nz-button nzPrimary (click)="editplanner()">
                edit
            </button> -->
            <!-- <div style="margin-left: 8px;">
                    <nz-tag [nzColor]="'#108ee9'">Read Only</nz-tag>
                </div> -->
        </div>
        <div class="col-md-4 d-flex flex-row justify-content-end">
            <div style="margin-right: 4px" class="d-flex flex-row gap-1">
                <div *ngIf="isAnyUserOnline">
                    <nz-avatar [nzText]="getInitials(editingUserName)"
                        style="color: #ffffff; background-color: #4caf50;" nz-tooltip
                        [nzTooltipTitle]="editingUserName + ' is editing the project plan'">
                    </nz-avatar>
                </div>
                <div *ngIf="isAnyUserOnline" class="mt-1 ml-1">
                    is Editing
                </div>
            </div>
            <!-- <button class="mt-1" nzSize="small" nzType="primary" nzTooltipTitle="Highlight Critical Path"
                    nzTooltipPlacement="top" nz-button nz-tooltip>
                    Read Only
                </button> -->
            <!-- <p-tag [style]="{ background: '#007ad9'}">
                    Read Only
                  </p-tag> -->
            <div style="margin-left: 8px; width: 300px;">
                <p-multiSelect [maxSelectedLabels]="0" [selectedItemsLabel]="'{featuresSelected} feature(s) active'"
                    [options]="featuresOption" [(ngModel)]="featuresSelected" (ngModelChange)="setPlannerFeatures()"
                    placeholder="Select feature options">
                </p-multiSelect>
            </div>
            <div style="margin-left: 8px">
                <button class="mt-1" nzSize="small" nz-button nzType="primary" (click)="enableFullscreen()" nz-tooltip
                    nzTooltipTitle="Maximize/Minimize Screen">
                    <i *ngIf="!fullScreen" class="fas fa-expand"></i>
                    <i *ngIf="fullScreen" class="fas fa-compress"></i>
                </button>
            </div>
            <div style="margin-left: 8px">
                <button nzSize="small" nz-button nzType="default" (click)="exportAsPdf()" nz-tooltip
                    nzTooltipTitle="Export to PDF/PNG" [disabled]="!downloadPDF && !displayGantt"><i
                        class="fa-solid fa-download" style="margin-right: 6px;"></i>Export
                </button>
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <nz-alert *ngIf="showSuccessMessage" nzType="success" nzMessage="Changes saved successfully"
            nzShowIcon></nz-alert>
    </div>

    <bryntum-gantt #gantt [baselinesFeature]="ganttConfig.baselinesFeature" [columnLines]="ganttConfig.columnLines"
        [columns]="ganttConfig.columns" [listeners]="ganttConfig.listeners"
        [dependencyEditFeature]="ganttConfig.dependencyEditFeature" [filterFeature]="ganttConfig.filterFeature"
        [sortFeature]="ganttConfig.sortFeature" [labelsFeature]="ganttConfig.labelsFeature"
        [progressLineFeature]="ganttConfig.progressLineFeature" [project]="ganttConfig.project"
        [rollupsFeature]="ganttConfig.rollupsFeature" [subGridConfigs]="ganttConfig.subGridConfigs"
        [dependencyIdField]="ganttConfig.dependencyIdField" [timeRangesFeature]="ganttConfig.timeRangesFeature"
        [taskMenuFeature]="ganttConfig.taskMenuFeature" [taskTooltipFeature]="ganttConfig.taskTooltipFeature"
        [cellEditFeature]="ganttConfig.cellEditFeature"
        [preserveScrollOnDatasetChange]="ganttConfig.preserveScrollOnDatasetChange" [timeAxis]="ganttConfig.timeAxis"
        [pdfExportFeature]="ganttConfig.pdfExportFeature" [columnReorderFeature]="ganttConfig.columnReorderFeature" [scrollButtonsFeature]="ganttConfig.scrollButtonsFeature">
    </bryntum-gantt>
</div>

<!-- FILTER DRAWER -->
<p-sidebar [(visible)]="visibleFilterDrawer" position="right" [style]="{ width: '500px' }" [showCloseIcon]="false">
    <ng-template pTemplate="header">
        <span class="font-semibold text-xl">Filters</span>
    </ng-template>
    <div class="row">
        <div class="col-md-12 d-flex flex-column">
            <div>
                <button nz-button nzType="primary" nzBlock (click)="clearAllFilters()">
                    <i class="fas fa-undo" style="margin-right: 4px"></i>Reset Filters
                </button>
            </div>
            <!-- SHOW ONLY OVERDUE -->
            <div class="row" style="margin-top: 30px">
                <div class="col-md-12 d-flex flex-row justify-content-between">
                    <div>
                        <span style="font-size: 14px; margin-right: 12px">Overdue Tasks</span>
                        <nz-switch [(ngModel)]="showOnlyOverdue"
                            (ngModelChange)="formulateFilterToolbox($event, 'selectedOverdue')"></nz-switch>
                    </div>
                    <div>
                        <span style="font-size: 14px; margin-right: 12px">Critical Tasks</span>
                        <nz-switch [(ngModel)]="showOnlyCritical"
                            (ngModelChange)="formulateFilterToolbox($event, 'selectedCritical')"></nz-switch>
                    </div>
                </div>
            </div>
            <!-- FOR SEARCH QUERY -->
            <div style="margin-top: 12px">
                <input style="height: 30px;
                font-size: 14px;
                width: 100%;" type="text" pInputText placeholder="Search by Title [min 3 characters]"
                    [(ngModel)]="searchTerm" (input)="formulateFilterToolbox($event, 'searchQuery')" />
            </div>
            <!-- FOR STATUS -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="statusOptions" [(ngModel)]="statusSelected"
                    (ngModelChange)="formulateFilterToolbox($event, 'selectedStatus')" placeholder="Filter by Status"
                    scrollHeight="250px" optionLabel="status">
                </p-multiSelect>
            </div>
            <!-- FOR INDICATORS -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="indicatorOptions" [(ngModel)]="indicatorSelected"
                    (ngModelChange)="formulateFilterToolbox($event, 'selectedIndicator')"
                    placeholder="Filter by Indicator" scrollHeight="250px" optionLabel="indicator">
                </p-multiSelect>
            </div>
            <!-- FOR PHASE -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="phaseOptions" [(ngModel)]="phaseSelected"
                    placeholder="Filter by Level 1 tasks"
                    (ngModelChange)="formulateFilterToolbox($event, 'selectedPhase')" scrollHeight="250px"
                    optionLabel="name">
                </p-multiSelect>
            </div>
            <!-- FOR WORKPACKAGE -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="workpackageOptions" [(ngModel)]="workpackageSelected"
                    placeholder="Filter by Level 2 tasks" scrollHeight="250px" optionLabel="name" (ngModelChange)="
            formulateFilterToolbox($event, 'selectedWorkpackage')
          ">
                </p-multiSelect>
            </div>
            <!-- FOR OWNER -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="ownerOptions" [(ngModel)]="ownerSelected"
                    placeholder="Filter by Owner" (ngModelChange)="formulateFilterToolbox($event, 'selectedOwner')"
                    scrollHeight="250px" optionLabel="name">
                </p-multiSelect>
            </div>
            <!-- FOR WORKSTREAM -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="workstreamOptions" [(ngModel)]="workstreamSelected"
                    (ngModelChange)="formulateFilterToolbox($event, 'selectedWorkstream')"
                    placeholder="Filter by Workstream Type" scrollHeight="250px" optionLabel="name">
                </p-multiSelect>
            </div>
            <!-- FOR ROLE -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="roletypeOptions" [(ngModel)]="roleSelected"
                    placeholder="Filter by Role Type" (ngModelChange)="formulateFilterToolbox($event, 'selectedRole')"
                    scrollHeight="250px" optionLabel="name">
                </p-multiSelect>
            </div>
            <!-- FOR TASK TYPE -->
            <div style="margin-top: 12px">
                <p-multiSelect class="filter-member" [options]="tasktypeOptions" [(ngModel)]="tasktypeSelected"
                    (ngModelChange)="formulateFilterToolbox($event, 'selectedTasktype')"
                    placeholder="Filter by Task Type" scrollHeight="250px" optionLabel="name">
                </p-multiSelect>
            </div>
            <!-- PLANNED FROM DATE -->
            <div class="row d-flex flex-row justify-content-between" style="margin-top: 28px">
                <div class="col-md-6">
                    <p-dropdown class="filter-toolbox filter-member mt-4" [options]="dateCombinationsPlannedfrom"
                        [(ngModel)]="combinationSelectedPlannedfrom" (ngModelChange)="
              formulateFilterToolbox(
                $event,
                'selectedDateCombinationPlannedfrom'
              )
            " placeholder="Select a date filter criteria" />
                </div>
                <div class="col-md-6">
                    <p-floatLabel>
                        <p-calendar [(ngModel)]="plannedFromSelected" [iconDisplay]="'input'" [showIcon]="true"
                            (ngModelChange)="formulateFilterToolbox($event, 'selectedPlannedfromDate')"
                            inputId="icondisplay" />
                        <label style="font-size: 14px;">Planned Start</label>
                    </p-floatLabel>
                </div>
            </div>
            <!-- PLANNED TO DATE -->
            <div class="row d-flex flex-row justify-content-between" style="margin-top: 28px">
                <div class="col-md-6">
                    <p-dropdown class="filter-toolbox filter-member mt-4" [options]="dateCombinationsPlannedto"
                        [(ngModel)]="combinationSelectedPlannedto" (ngModelChange)="
              formulateFilterToolbox($event, 'selectedDateCombinationPlannedto')
            " placeholder="Select a date filter criteria" />
                </div>
                <div class="col-md-6">
                    <p-floatLabel>
                        <p-calendar class="mt-4" [(ngModel)]="plannedToSelected" [iconDisplay]="'input'"
                            [showIcon]="true" (ngModelChange)="formulateFilterToolbox($event, 'selectedPlannedtoDate')"
                            inputId="icondisplay" />
                        <label style="font-size: 14px;">Planned End</label>
                    </p-floatLabel>
                </div>
            </div>
        </div>
    </div>
</p-sidebar>