import { NgModule } from '@angular/core';

// Required PrimeNG Modules
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { BadgeModule } from 'primeng/badge';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FloatLabelModule } from 'primeng/floatlabel';
import { TagModule } from 'primeng/tag';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
@NgModule({
  imports: [
    SidebarModule,      // For Sidebar
    MultiSelectModule,  // For MultiSelect Dropdowns
    DropdownModule,     // For Single Select Dropdowns
    CalendarModule,      // For Calendar component
    InputTextModule,
    BadgeModule,
    ConfirmPopupModule,
    FloatLabelModule,
  ],
  exports: [
    SidebarModule,      // Export Sidebar
    MultiSelectModule,  // Export MultiSelect Dropdowns
    DropdownModule,     // Export Single Select Dropdowns
    CalendarModule,      // Export Calendar component
    InputTextModule,
    BadgeModule,
    ConfirmPopupModule,
    FloatLabelModule,
    TagModule,
    ConfirmDialogModule
  ]
})
export class AppPrimeNGModule {}
