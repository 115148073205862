import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loggedIn = false;
  ktoken: string;
  fullName: string
  constructor(private cookieService: CookieService) {
    this.getktoken();
    this.getfullName();
    // console.log(this.getktoken())
    // console.log(this.getfullName())
  }

  getktoken() {
    console.log("Is present in cookie", this.getCookie('ktoken'))
    return this.cookieService.get('ktoken');
    // return this.getCookie('ktoken');
  }

  getfullName() {
    // return "KTern Admin ashok"
    return this.getCookie('fullName');
  }
  getUserID(){
    console.log("id",this.getCookie('_id') )
    return this.getCookie('_id');
  }
  login(username: string, password: string): boolean {
    // Simulate a real authentication process
    if (username === 'admin' && password === 'admin') {
      this.loggedIn = true;
      localStorage.setItem('authToken', 'dummyToken'); // Store token in local storage
      return true;
    }
    return false;
  }

  isAuthenticated(): boolean {
    // Check if the user is authenticated by verifying the presence of a token
    return localStorage.getItem('authToken') !== null;
  }

  logout(): void {
    // Remove the token and log out the user
    localStorage.removeItem('authToken');
    this.loggedIn = false;
  }

  getToken() {
    return localStorage.getItem("ktoken");
  }

  getUserPayload() {
    const token = this.getToken();
    if (token) {
      const userPayload = atob(token.split(".")[1]);
      console.log(userPayload);
      return JSON.parse(userPayload);
    } else {
      return null;
    }
  }

  isLoggedIn() {
    const userPayload = this.getUserPayload();
    if (userPayload) {
      return userPayload.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  getUsername() {
    // console.log(localStorage.getItem("fullName"))
    return localStorage.getItem("fullName");
  }

  getProjectid() {
    return localStorage.getItem("_id")
  }
  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();

      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }

    return null;
  }
}