import { Injectable } from "@angular/core";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { Observable, of, timer, EMPTY, Subject } from "rxjs";
import {
  filter,
  map,
  switchMap,
  retryWhen,
  delay,
  tap,
  delayWhen,
  catchError,
  switchAll
} from "rxjs/operators";
import { environment } from '../../../environments/environment';
export const WS_ENDPOINT = environment.wsEndpoint;
export const RECONNECT_INTERVAL = environment.reconnectInterval;
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
    userId: string = null;
  
    constructor() {
      this.userId = "5ccea0fb6d01b0599e0f1d47";
    }
    connection$: WebSocketSubject<any>;
    private messagesSubject$ = new Subject();
    public messages$ = this.messagesSubject$.pipe(
      switchAll(),
      catchError((e) => {
        throw e;
      })
    );
    RETRY_SECONDS = 10;
    public connect(cfg: { reconnect: boolean } = { reconnect: false }): Observable<any> {
      if (true && this.userId !== null) {
        let url = WS_ENDPOINT + "?user=" + this.userId + "&type=2";
        // console.log(url);
        return of(url).pipe(
          filter((apiUrl) => !!apiUrl),
          // https becomes wws, http becomes ws
          map((apiUrl) => apiUrl.replace(/^http/, "ws")),
          switchMap((wsUrl) => {
            if (this.connection$) {
              this.connection$.subscribe(
                (message) => {
                  console.log(message);
                  this.showSuccessModal();
                  // console.log("Message");
                  // let messageJSON = of(JSON.parse(JSON.stringify(message)));
                  // this.messagesSubject$.next('Hey');
                  this.messagesSubject$.next(message);
                },
                (err) => {
                  this.connection$ = undefined;
                  console.log(err);
                }
              );
              return this.connection$;
            } else {
              console.log(wsUrl);
              this.connection$ = webSocket({
                url: wsUrl,
                closeObserver: {
                  next: () => {
                    console.log("[DataService]: connection closed");
                    this.connection$ = undefined;
                    this.connect({ reconnect: true });
                  }
                }
              });
              this.connection$.subscribe(
                (message) => {
                  // console.log(message);
                  // console.log(typeof message);
                  // console.log("Message");
                  // this.messagesSubject$.next("Hey");
                  let messageJSON = of(JSON.parse(JSON.stringify(message)));
                  this.messagesSubject$.next(messageJSON);
                },
                (err) => {
                  this.connection$ = undefined;
                  // console.log(err);
                  this.reconnect;
                }
              );
              return this.connection$;
            }
          }),
          retryWhen((errors) => errors.pipe(delay(this.RETRY_SECONDS)))
        );
      } else {
        // console.log('No User ID');
        // return Observable.of({});
      }
    }
    send(data: any) {
      if (this.connection$) {
        console.log("Connection Sending");
        this.connection$.next(data);
      } else {
        console.error("Did not send data, open a connection first");
      }
    }
    private reconnect(o: Observable<any>): Observable<any> {
      if (true) {
        return o.pipe(
          retryWhen((errors) =>
            errors.pipe(
              tap((val) => console.log("[Data Service] Try to reconnect", val)),
              delayWhen((_) => timer(this.RETRY_SECONDS))
            )
          )
        );
      }
    }
  
    closeConnection() {
      console.log("Connection closed");
      if (this.connection$) {
        this.connection$.complete();
        this.connection$ = null;
      }
    }
  
    showSuccessModal() {
    window.alert("socket from backend")
    }
  }
